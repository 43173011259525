import styles from './Startseite.module.scss';
import { useSelector } from 'react-redux';
import { NavigationEntry, NavigationGroup } from '../../store/Navigation.store';
import { Link } from 'react-router-dom';
import type { RootState } from '../../configureStore';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';

const Startseite = (): JSX.Element => {
    const menuEntries: NavigationGroup[] = useSelector((rootState: RootState): NavigationGroup[] => rootState.navigation.navigationGroups);
    useDocumentTitle('Deutsche Tiernahrung Cremer Partnerportal', { disablePrefix: true });
    return (
        <div className={styles._page}>
            <h2>DEU·PA</h2>
            <p>Willkommen im Deutsche Tiernahrung Cremer Partnerportal</p>
            <div className={styles._navigationContainer}>
                {menuEntries.map((group, groupIndex) => (
                    <div className={styles._group} key={groupIndex}>
                        <h4>{group.groupLabel}</h4>
                        <div className={styles._navigationGroup}>
                            {group.entries.map((entry: NavigationEntry, entryIndex) => {
                                if (entry.name === 'accountLoeschen') {
                                    return null;
                                }
                                if (entry.isExternalLink) {
                                    return (
                                        <a
                                            data-cy={`navigation-cards${entry.name}`}
                                            key={entryIndex}
                                            href={entry.route}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={styles._navigationEntry}
                                        >
                                            {entry.image && <img className={styles._navigationImage} src={entry.image} alt=" " />}
                                            <div className={styles._textWrapper}>
                                                <span className={styles._navigationText}>{entry.label}</span>
                                            </div>
                                        </a>
                                    );
                                }

                                return (
                                    <Link
                                        data-cy={`navigation-cards${entry.name}`}
                                        key={entryIndex}
                                        to={entry.route}
                                        className={styles._navigationEntry}
                                    >
                                        {entry.image && <img className={styles._navigationImage} src={entry.image} alt=" " />}
                                        <div className={styles._textWrapper}>
                                            <span className={styles._navigationText}>{entry.label}</span>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Startseite;
