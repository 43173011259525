import { TableCell } from '../../../../ui-components/Table';
import TextInput from '../../../../ui-components/TextInput/TextInput';
import { PropertyEntryReadonlyProps } from '../types';
import { BestellpositionArtikelLoseWareView } from '../../../../types';

export const SiloTableCell = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps<BestellpositionArtikelLoseWareView>): JSX.Element => {
    if (readonly) {
        return (
            <TableCell text storniert={bestellposition.storno}>
                <span>{bestellposition.silo ? bestellposition.silo : ''}</span>
            </TableCell>
        );
    }

    return (
        <TableCell storniert={bestellposition.storno} number input>
            <TextInput
                name={`bestellpositionenView[${bestellposition.index}].silo`}
                id={`bestellpositionenView[${bestellposition.index}].silo`}
                type="text"
                data-testid={`${bestellposition.nummer}-silo`}
                maxLength={40}
                disabled={bestellposition.storno || disabled}
                inTableCell
            />
        </TableCell>
    );
};
