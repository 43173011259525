import {
    routeFromWorkflowStep,
    routes,
    SET_CURRENT_WORKFLOW_STEP_SUCCEEDED,
    SetCurrentWorkflowStepSucceededAction,
    WorkflowStep,
} from './Workflow.store';
import { RegistrierungsStatus, Rollen } from '../shared/types/enums';
import type { RootState } from '../configureStore';
import neueBestellungImage from './../assets/images/NeueBestellung.jpg';
import meineBestellungenImage from './../assets/images/MeineBestellungen.jpg';
import meineLieferungenImage from './../assets/images/MeineLieferungen.jpg';
import bestellungFortsetzenImage from './../assets/images/BestellungFortsetzen.jpg';
import auftragskorbImage from './../assets/images/Auftragskorb.jpg';
import kfzKennzeichenImage from './../assets/images/KFZKennzeichen.jpg';
import standorteImage from './../assets/images/StandorteUndLieferzeiten.jpg';
import nprechnerImage from './../assets/images/NPRechner.jpg';
import fragenUndAntwortenImage from './../assets/images/fragenUndAntworten.jpg';
import kontraktuebersichtImage from './../assets/images/Kontraktuebersicht_Vertrag.jpg';
import dokumenteImage from './../assets/images/dokumente.jpg';
import nachrichtenImage from './../assets/images/Nachrichten.jpg';
import futteruntersuchungImage from './../assets/images/Futteruntersuchung.jpg';
import rationsserviceImage from './../assets/images/Rationsservice.jpg';
import accountLoeschenImage from './../assets/images/AccountLoeschen.jpg';
import agrarwetterImage from './../assets/images/Agrarwetter.jpg';
import marktchartsImage from './../assets/images/Marktcharts.jpg';
import { clearBestellung, sendBestellungSucceeded } from './Bestellung.store';
import { ReactElement } from 'react';
import Config from '../shared/config';
import { ACCOUNT_DELETION_EMAIL } from '../shared/constants';

const { isApp } = Config.getConfig();

export const GENERATE_NAVIGATION_ENTRIES = 'GENERATE_NAVIGATION_ENTRIES';

export interface GenerateNavigationEntriesAction {
    type: typeof GENERATE_NAVIGATION_ENTRIES;
    benutzerRollen: Rollen[];
    currentWorkflowStep?: WorkflowStep;
}

export function generateNavigationEntries(benutzerRollen: Rollen[], currentWorkflowStep?: WorkflowStep): GenerateNavigationEntriesAction {
    return {
        type: GENERATE_NAVIGATION_ENTRIES,
        benutzerRollen,
        currentWorkflowStep,
    };
}

export type NavigationEntry = {
    label: ReactElement;
    name: string;
    route: string;
    image?: string;
    isExternalLink?: boolean;
    onClick?: () => void;
};

export type NavigationGroup = {
    groupLabel: ReactElement | string;
    entries: NavigationEntry[];
};

export type NavigationState = {
    isAdmin: boolean;
    isDebitor: boolean;
    isInteressent: boolean;
    isWarenempfaenger: boolean;
    navigationGroups: NavigationGroup[];
    benutzerRollen: Rollen[];
};

export type NavigationActions = GenerateNavigationEntriesAction;

export const INITIAL_NAVIGATION_STATE: NavigationState = {
    isAdmin: false,
    isDebitor: false,
    isInteressent: true,
    isWarenempfaenger: false,
    navigationGroups: [],
    benutzerRollen: [],
};

function createMenuEntry(label: string | ReactElement, name: string, route: string, image = '', isExternalLink = false): NavigationEntry {
    return {
        label: typeof label === 'string' ? <>{label}</> : label,
        name,
        route,
        image,
        isExternalLink,
    };
}
function createNavigationGroup(label: string, entries: NavigationEntry[]): NavigationGroup {
    return {
        groupLabel: <>{label}</>,
        entries,
    };
}

function createServiceGroup(specificGroup: NavigationGroup): NavigationGroup {
    return createNavigationGroup('Services', [
        ...specificGroup.entries,
        createMenuEntry('Agrarwetter', routes.agrarwetter, routes.agrarwetter, agrarwetterImage),
        createMenuEntry('Marktcharts', routes.marktcharts, routes.marktcharts, marktchartsImage),
        createMenuEntry(
            <>
                RumiTop<sup>&#174;</sup> N+P-Effizienzrechner
            </>,
            routes.npRechner,
            routes.npRechner,
            nprechnerImage
        ),
        createMenuEntry(
            'Standorte und Verladezeiten',
            '/uebersicht-verladezeiten',
            'https://www.deuka.de/unternehmen/uebersicht-verladezeiten/',
            standorteImage,
            true
        ),
        createMenuEntry('Fragen und Antworten', routes.fragenUndAntworten, routes.fragenUndAntworten, fragenUndAntwortenImage),
    ]);
}

function createDebitorEntries(existingBestellungEntry?: NavigationEntry): NavigationGroup[] {
    const bestellungGroup = createNavigationGroup('Bestellungen', [
        createMenuEntry('Neue Bestellung', routes.auswahlFuttertyp, routes.auswahlFuttertyp, neueBestellungImage),
        createMenuEntry('Meine Bestellungen', routes.bestelluebersicht, routes.bestelluebersicht, meineBestellungenImage),
        createMenuEntry('Meine Lieferungen', routes.lieferungsuebersicht, routes.lieferungsuebersicht, meineLieferungenImage),
        createMenuEntry(<>Auftrags&shy;korb</>, routes.auftragsuebersicht, routes.auftragsuebersicht, auftragskorbImage),
    ]);

    if (existingBestellungEntry) {
        bestellungGroup.entries.splice(1, 0, existingBestellungEntry);
    }

    const meineDatenGroup = createNavigationGroup('Meine Daten', [
        createMenuEntry(
            <>KFZ-Kennzeichen und&nbsp;Speditionen</>,
            routes.kfzKennzeichenUndSpeditionen,
            routes.kfzKennzeichenUndSpeditionen,
            kfzKennzeichenImage
        ),
    ]);

    if (isApp()) {
        meineDatenGroup.entries.push(
            createMenuEntry(<>Account löschen</>, routes.accountLoeschen, `mailto:${ACCOUNT_DELETION_EMAIL}`, accountLoeschenImage, true)
        );
    }

    const roleSpecificEntries = createNavigationGroup('hallo', [
        createMenuEntry(<>Kontrakt&shy;übersicht</>, routes.kontraktuebersicht, routes.kontraktuebersicht, kontraktuebersichtImage),
        createMenuEntry(<>Rations&shy;service</>, routes.rationsservice, routes.rationsservice, rationsserviceImage),
        createMenuEntry(<>Futter&shy;mittelanalyse</>, routes.futteruntersuchungen, routes.futteruntersuchungen, futteruntersuchungImage),
        createMenuEntry(<>Meine Dokumente</>, routes.dokumentenuebersicht, routes.dokumentenuebersicht, dokumenteImage),
    ]);
    const serviceGroup = createServiceGroup(roleSpecificEntries);

    if (isApp()) {
        const nachrichtenEntry = createMenuEntry(<>Nachrichten</>, routes.nachrichten, routes.nachrichten, nachrichtenImage, true);
        serviceGroup.entries.push(nachrichtenEntry);
    }

    return [bestellungGroup, serviceGroup, meineDatenGroup];
}

function createWarenempfaengerEntries(existingBestellungEntry?: NavigationEntry): NavigationGroup[] {
    const bestellungGroup = createNavigationGroup('Bestellungen', [
        createMenuEntry('Neue Bestellung', routes.auswahlFuttertyp, routes.auswahlFuttertyp, neueBestellungImage),
        createMenuEntry('Meine Bestellungen', routes.bestelluebersicht, routes.bestelluebersicht, meineBestellungenImage),
        createMenuEntry('Meine Lieferungen', routes.lieferungsuebersicht, routes.lieferungsuebersicht, meineLieferungenImage),
    ]);
    if (existingBestellungEntry) {
        bestellungGroup.entries.splice(1, 0, existingBestellungEntry);
    }

    const roleSpecificEntries = createNavigationGroup('hallo', [
        createMenuEntry(<>Rations&shy;service</>, routes.rationsservice, routes.rationsservice, rationsserviceImage),
        createMenuEntry(<>Futter&shy;mittelanalyse</>, routes.futteruntersuchungen, routes.futteruntersuchungen, futteruntersuchungImage),
        createMenuEntry(<>Meine Dokumente</>, routes.dokumentenuebersicht, routes.dokumentenuebersicht, dokumenteImage),
    ]);
    const serviceGroup = createServiceGroup(roleSpecificEntries);
    if (isApp()) {
        const nachrichtenEntry = createMenuEntry(<>Nachrichten</>, routes.nachrichten, routes.nachrichten, nachrichtenImage, true);
        serviceGroup.entries.push(nachrichtenEntry);
    }

    return [bestellungGroup, serviceGroup];
}

function createAdminEntries(): NavigationGroup[] {
    return [
        {
            groupLabel: 'Administration',
            entries: [
                createMenuEntry(
                    'Offene Registrierungen',
                    `${routes.benutzer}/${RegistrierungsStatus.NEU}`,
                    `${routes.benutzer}/${RegistrierungsStatus.NEU}`
                ),
                createMenuEntry(
                    <>Benutzer&shy;übersicht</>,
                    `${routes.benutzer}/${RegistrierungsStatus.REGISTRIERT}`,
                    `${routes.benutzer}/${RegistrierungsStatus.REGISTRIERT}`
                ),
                createMenuEntry(<>Partner&shy;übersicht</>, routes.partner, routes.partner),
                createMenuEntry(<>Preislisten&shy;kennzeichen</>, routes.preislistenkennzeichen, routes.preislistenkennzeichen),
            ],
        },
    ];
}

function generateNavigation(benutzerRollen: Rollen[], existingBestellungEntry?: NavigationEntry): NavigationGroup[] {
    if (benutzerRollen.includes(Rollen.DEBITOR)) {
        return createDebitorEntries(existingBestellungEntry);
    }

    if (benutzerRollen.includes(Rollen.RECIPIENT)) {
        return createWarenempfaengerEntries(existingBestellungEntry);
    }

    if (benutzerRollen.includes(Rollen.ADMIN)) {
        return createAdminEntries();
    }
    return [];
}

const bestellungFortsetzenEntry: NavigationEntry = createMenuEntry(
    'Bestellung fortsetzen',
    routes.auswahlFuttertyp,
    '/bestellung-fortsetzen',
    bestellungFortsetzenImage
);

type BestellungActions = { type: typeof sendBestellungSucceeded.type } | { type: typeof clearBestellung.type };

function generateNavigationForWorkflowStep(benutzerRollen: Rollen[], currentWorkflowStep?: WorkflowStep) {
    if (
        currentWorkflowStep &&
        currentWorkflowStep !== WorkflowStep.AuswahlBestellprozesstyp &&
        currentWorkflowStep !== WorkflowStep.UNDECIDED
    ) {
        return generateNavigation(benutzerRollen, {
            ...bestellungFortsetzenEntry,
            route: routeFromWorkflowStep(currentWorkflowStep),
        });
    }

    return generateNavigation(benutzerRollen);
}

export function navigationReducer(
    state = INITIAL_NAVIGATION_STATE,
    action: NavigationActions | SetCurrentWorkflowStepSucceededAction | BestellungActions
): NavigationState {
    switch (action.type) {
        case GENERATE_NAVIGATION_ENTRIES:
            return {
                ...state,
                isAdmin: action.benutzerRollen.includes(Rollen.ADMIN),
                isDebitor: action.benutzerRollen.includes(Rollen.DEBITOR),
                isInteressent: action.benutzerRollen.includes(Rollen.PROSPECT),
                isWarenempfaenger: action.benutzerRollen.includes(Rollen.RECIPIENT),
                navigationGroups: generateNavigationForWorkflowStep(action.benutzerRollen, action.currentWorkflowStep),
                benutzerRollen: action.benutzerRollen,
            };
        case SET_CURRENT_WORKFLOW_STEP_SUCCEEDED:
            return {
                ...state,
                navigationGroups: generateNavigationForWorkflowStep(state.benutzerRollen, action.currentStep),
            };
        case sendBestellungSucceeded.type:
            return {
                ...state,
                navigationGroups: generateNavigation(state.benutzerRollen),
            };
        case clearBestellung.type:
            return {
                ...state,
                navigationGroups: generateNavigation(state.benutzerRollen),
            };
        default:
            return state;
    }
}

const selectNavigation = (state: RootState) => state.navigation;
export const selectIsDebitor = (state: RootState): boolean => selectNavigation(state).isDebitor;
export const selectIsWarenempfaenger = (state: RootState): boolean => selectNavigation(state).isWarenempfaenger;
