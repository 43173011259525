import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import { routes } from '../../shared/constants';
import type { Dispatch, MouseEvent } from 'react';
import { isExistingBestellposition, isStornierbarePosition } from '../../shared/helper/bestellungen-helper';
import { ArtikelTableValues } from '../../shared/types';

type BestellansichtButtonsProps = { setTriedToSubmit: Dispatch<boolean>; setStorniereBestellung: Dispatch<boolean> };

function submitAfterNextRender(submitForm: () => void) {
    setTimeout(() => {
        // Hack: A different validation schema is used depending on which button is pressed.
        // This happens asynchronously, so we need to wait for the next render to submit the form.
        submitForm();
    }, 0);
}

export const BestellansichtButtons = ({ setTriedToSubmit, setStorniereBestellung }: BestellansichtButtonsProps): JSX.Element => {
    const history = useHistory();
    const { values, setFieldValue, submitForm } = useFormikContext<ArtikelTableValues>();

    const onNextClick = (): void => {
        setTriedToSubmit(true);
    };

    const navigateToBestelluebersicht = () => {
        history.push(routes.bestelluebersicht);
    };

    const onBestellungStornieren = (event: MouseEvent): void => {
        event.preventDefault();

        showConfirmationDialog({
            message: <>Sind Sie sich sicher, dass Sie diese Bestellung stornieren möchten?</>,
            okButtonText: 'Bestellung stornieren',
            onOkCallback: () => {
                values.bestellpositionenView
                    .filter((position) => isExistingBestellposition(position) && isStornierbarePosition(position))
                    .forEach((bestellposition) => {
                        setFieldValue(`bestellpositionenView[${bestellposition.index}].storno`, true);
                    });
                setStorniereBestellung(true);
                submitAfterNextRender(submitForm);
            },
        });
    };

    return (
        <ButtonGroup>
            <Button onClick={navigateToBestelluebersicht}>Zurück</Button>
            <Button onClick={onBestellungStornieren}>Bestellung Stornieren</Button>
            <Button type="submit" onClick={onNextClick}>
                Änderungswunsch verbindlich verschicken
            </Button>
        </ButtonGroup>
    );
};
