import Keycloak from 'keycloak-js';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHasOpenBestellung } from '../../../../store/Workflow.store';
import { clearBestellung } from '../../../../store/Bestellung.store';
import { ALLOW_COOKIES_KEY } from '../../../../localStorage';
import { showConfirmationDialog } from '../../ConfirmationDialog/confirmationDialog';
import styles from '../Shell.module.scss';
import accountIcon from '../../../../assets/icon-account.svg';
import FlutterBridge from '../../../../flutter/flutterBridge';

type AccountProps = {
    username: string;
    keycloak: Partial<Keycloak.KeycloakInstance>;
    setMenuIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const Account = ({ username, keycloak, setMenuIsOpen }: AccountProps): ReactElement => {
    const dispatch = useDispatch();
    const hasOpenBestellung = useSelector(selectHasOpenBestellung);

    const logout = (logoutUrl: string): void => {
        dispatch(clearBestellung());
        FlutterBridge.sendToken(null);
        const allowCookies = localStorage.getItem(ALLOW_COOKIES_KEY);
        localStorage.clear();
        if (allowCookies !== null) {
            localStorage.setItem(ALLOW_COOKIES_KEY, allowCookies);
        }

        window.location.href = logoutUrl;
    };

    const confirmLogoutDialog = (logoutUrl: string): void => {
        showConfirmationDialog({
            message: (
                <>
                    Sie haben noch eine Bestellung, die nicht abgeschlossen ist. Wenn Sie fortfahren werden Ihre Bestellpositionen gelöscht.
                </>
            ),
            okButtonText: 'Bestellpositionen löschen',
            onOkCallback: () => {
                logout(logoutUrl);
            },
        });
    };

    const shouldLogout = () => {
        setMenuIsOpen(false);
        const logoutUrl = (keycloak.createLogoutUrl && keycloak?.createLogoutUrl()) || '';
        if (hasOpenBestellung) {
            confirmLogoutDialog(logoutUrl);
        } else {
            logout(logoutUrl);
        }
    };

    return (
        <div>
            <div className={styles._accountContainer}>
                <img src={accountIcon} className={styles._accountIcon} alt="Benutzer" />
                <div>
                    <label className={styles._menuLabel}>{username}</label>
                    <button onClick={shouldLogout} className={styles._account} id="login-logout-button">
                        Abmelden
                    </button>
                </div>
            </div>
        </div>
    );
};
