import uniqBy from 'lodash/uniqBy';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BestellungErpStatus, Order, RequestState } from '../shared/types/enums';
import type {
    BestellpositionDetails,
    BestellpositionView,
    BestellungDetails,
    ErpRueckmeldung,
    Filter,
    Rechnungsempfaenger,
} from '../shared/types';
import type { RootState } from '../configureStore';
import {
    bestellpositionDetailsToBestellpositionView,
    bestellpositionenViewFromBestellpositionenDetailsAndArtikeln,
    isLoseWare,
    isSackwareView,
    isSackwareDetails,
} from '../shared/helper/bestellungen-helper';
import { sortArtikelByNumber } from '../shared/helper/artikel-helper';
import { selectArtikelLoseWareList } from './ArtikelLoseWare.store';
import dayjs from 'dayjs';
import { emptyBestellungDetails } from '../shared/types/defaultValues';
import { ArtikelSackwarenSelectors } from './ArtikelSackware.store';

export interface GetBestellungAction {
    bestellnummer: string;
}

export interface GetBestellungenAction {
    page: number;
    size: number;
    sortColumn: string;
    sortOrder: Order;
    filter?: Filter;
    includeBestellpositionen?: boolean;
}

export interface GetBestellungenSucceededAction {
    bestellungen: BestellungDetails[];
    totalCount: number;
}

export const INITIAL_BESTELLUEBERSICHT_STATE: BestelluebersichtState = {
    bestellungen: [],
    totalCount: 0,
    bestellungenLoadingState: RequestState.INITIAL,
    currentBestellung: emptyBestellungDetails,
    currentBestellungLoadingState: RequestState.INITIAL,
    currentSammelbestellung: [],
};

export type BestelluebersichtState = {
    bestellungen: BestellungDetails[];
    totalCount: number;
    bestellungenLoadingState: RequestState;
    currentBestellung: BestellungDetails;
    currentBestellungLoadingState: RequestState;
    currentSammelbestellung: string[];
};

const selectBestellungen = (state: RootState): BestellungDetails[] => state.bestelluebersicht.bestellungen;
const selectBestellungenTotalCount = (state: RootState): number => state.bestelluebersicht.totalCount;
const selectCurrentBestellung = (state: RootState): BestellungDetails => state.bestelluebersicht.currentBestellung;
const selectCurrentSammelbestellung = (state: RootState): string[] => state.bestelluebersicht.currentSammelbestellung;

const selectCurrentSammelbestellungWithBestellungen = (state: RootState): BestellungDetails[] =>
    selectBestellungen(state).filter((bestellung) => selectCurrentSammelbestellung(state).includes(bestellung.bestellnummer));

const selectHasValidCurrentSammelbestellung = createSelector(
    selectCurrentSammelbestellungWithBestellungen,
    (currentSammelbestellungWithBestellungen: BestellungDetails[]): boolean => {
        const hasBestellungWithEigenemErp = currentSammelbestellungWithBestellungen.some((bestellung) => bestellung.debitorEigenesErp);
        return currentSammelbestellungWithBestellungen.length >= 1 && !hasBestellungWithEigenemErp;
    }
);

const selectArtikelNummernOfCurrentBestellung = createSelector(selectCurrentBestellung, (bestellung: BestellungDetails): string[] => {
    return bestellung.bestellpositionen.map((bestellposition) => bestellposition.nummer);
});

const selectRechnungsempfaengerFromCurrentSammelbestellungen = (state: RootState): Rechnungsempfaenger[] => {
    return selectCurrentSammelbestellungWithBestellungen(state).map((c) => c.rechnungsempfaenger);
};

const selectUniqueRechnungsempfaengerFromCurrentSammelbestellungen = (state: RootState): Rechnungsempfaenger[] => {
    return uniqBy(selectRechnungsempfaengerFromCurrentSammelbestellungen(state), (r) => r.partnerNummer);
};

const selectBestellpositionenAsBestellpositionenView = createSelector(
    selectCurrentBestellung,
    (bestellung: BestellungDetails): BestellpositionView[] => {
        return bestellpositionDetailsToBestellpositionView(bestellung.bestellpositionen);
    }
);

const selectBestellpositionenSackwareView = createSelector(
    selectBestellpositionenAsBestellpositionenView,
    (bestellpositionenViews: BestellpositionView[]) => {
        return bestellpositionenViews.filter(isSackwareView).sort(sortArtikelByNumber);
    }
);

const selectBestellpositionenLoseWareView = createSelector(
    selectBestellpositionenAsBestellpositionenView,
    (bestellpositionenViews: BestellpositionView[]) => {
        return bestellpositionenViews.filter(isLoseWare).sort(sortArtikelByNumber);
    }
);

const selectBestellpositionenViewFromBestellpositionenDetailsAndArtikeln = createSelector(
    selectCurrentBestellung,
    selectArtikelLoseWareList,
    ArtikelSackwarenSelectors.list,
    (bestellung, artikelLoseWareList, artikelSackwareList): BestellpositionView[] => {
        return bestellpositionenViewFromBestellpositionenDetailsAndArtikeln(
            bestellung.bestellpositionen,
            artikelSackwareList,
            artikelLoseWareList
        );
    }
);

const isCurrentPositionGesperrt = (nummer: string, positionsnummer?: number): ((state: RootState) => boolean) => {
    return (state: RootState) => {
        return selectSingleBestellposition(state, nummer, positionsnummer)?.erpStatus === BestellungErpStatus.G;
    };
};

const selectErpRuckmeldungForPosition = (nummer: string, positionsnummer?: number) => {
    return (state: RootState): Partial<ErpRueckmeldung> | undefined => {
        const bestellposition = selectSingleBestellposition(state, nummer, positionsnummer);

        if (bestellposition) {
            return {
                erpStatus: bestellposition.erpStatus,
                modifikationskommentar: bestellposition.modifikationskommentar,
                modifikationsstatus: bestellposition.modifikationsstatus,
            };
        }
        return;
    };
};

const selectCurrentBestellpositionen = createSelector(
    selectCurrentBestellung,
    (bestellung: BestellungDetails) => bestellung.bestellpositionen
);

const selectSingleBestellposition = (state: RootState, nummer: string, positionsnummer?: number): BestellpositionDetails | undefined => {
    if (!positionsnummer) {
        return;
    }
    return selectCurrentBestellpositionen(state).find(
        (position) => position.nummer === nummer && position.positionsnummer === positionsnummer
    );
};

const selectHasAenderbarBisExpiredForPosition = (nummer: string, positionsnummer?: number): ((state: RootState) => boolean) => {
    return (state: RootState) => {
        const aenderbarBis = selectSingleBestellposition(state, nummer, positionsnummer)?.aenderbarBis;
        return aenderbarBis ? dayjs(aenderbarBis).isBefore(dayjs()) : false;
    };
};

const selectAenderbarBisDateForPosition = (nummer: string, positionsnummer?: number) => {
    return (state: RootState): string | undefined => {
        const aenderbarBis = selectSingleBestellposition(state, nummer, positionsnummer)?.aenderbarBis;

        if (!aenderbarBis) {
            return;
        }

        const aenderbarBisDate = dayjs(aenderbarBis);
        if (!aenderbarBisDate.isValid()) {
            return;
        }
        return aenderbarBisDate.format('YYYY-MM-DD HH:mm');
    };
};

const selectCurrentBestellungHasSackwareWithModifikationsergebnis = (state: RootState): BestellpositionDetails | undefined => {
    const bestellung = selectCurrentBestellung(state);
    if (bestellung.isDraft) {
        return;
    }

    return bestellung.bestellpositionen.filter(isSackwareDetails).find((position) => {
        if (
            position.hasOwnProperty('modifikationsstatus') &&
            position.modifikationsstatus != null &&
            position.hasOwnProperty('modifikationskommentar')
        ) {
            return position;
        }
        return;
    });
};

const selectBestellpositionenViewNummern = createSelector(
    selectBestellpositionenViewFromBestellpositionenDetailsAndArtikeln,
    (bestellpositionen): string[] => {
        return bestellpositionen.map((bestellposition) => bestellposition.nummer);
    }
);

export const BestelluebersichtSelectors = {
    bestellungen: selectBestellungen,
    bestellungenTotalCount: selectBestellungenTotalCount,
    currentBestellung: selectCurrentBestellung,
    currentBestellungHasSackwareWithModifikationsergebnis: selectCurrentBestellungHasSackwareWithModifikationsergebnis,
    bestellpositionenSackwareView: selectBestellpositionenSackwareView,
    bestellpositionenLoseWareView: selectBestellpositionenLoseWareView,
    artikelNummernOfCurrentBestellung: selectArtikelNummernOfCurrentBestellung,
    currentSammelbestellung: selectCurrentSammelbestellung,
    hasValidCurrentSammelbestellung: selectHasValidCurrentSammelbestellung,
    uniqueRechnungsempfaengerFromCurrentSammelbestellungen: selectUniqueRechnungsempfaengerFromCurrentSammelbestellungen,
    bestellpositionenViewBestellpositionDetails: selectBestellpositionenViewFromBestellpositionenDetailsAndArtikeln,
    bestellpositionenViewNummern: selectBestellpositionenViewNummern,
    getErpRueckmeldungForPosition: selectErpRuckmeldungForPosition,
    getAenderbarBisDateForPosition: selectAenderbarBisDateForPosition,
    hasAenderbarBisExpiredForPosition: selectHasAenderbarBisExpiredForPosition,
    currentBestellungLoading(state: RootState): boolean {
        return state.bestelluebersicht.currentBestellungLoadingState === RequestState.LOADING;
    },
    currentBestellungLoadFailed(state: RootState): boolean {
        return state.bestelluebersicht.currentBestellungLoadingState === RequestState.FAILED;
    },
    bestellungenLoading(state: RootState): boolean {
        return state.bestelluebersicht.bestellungenLoadingState === RequestState.LOADING;
    },
    bestellungenLoadFailed(state: RootState): boolean {
        return state.bestelluebersicht.bestellungenLoadingState === RequestState.FAILED;
    },
    isCurrentPositionGesperrt: isCurrentPositionGesperrt,
};

const bestelluebersichtSlice = createSlice({
    name: 'bestelluebersicht',
    initialState: INITIAL_BESTELLUEBERSICHT_STATE,
    reducers: {
        resetCurrentBestellung(state) {
            state.currentBestellung = emptyBestellungDetails;
            state.currentBestellungLoadingState = RequestState.INITIAL;
        },
        getBestellung(state, _: PayloadAction<GetBestellungAction>) {
            state.currentBestellung = emptyBestellungDetails;
            state.currentBestellungLoadingState = RequestState.LOADING;
        },
        getBestellungSucceeded(state, action: PayloadAction<BestellungDetails>) {
            state.currentBestellung = action.payload;
            state.currentBestellungLoadingState = RequestState.SUCCESSFUL;
        },
        getBestellungFailed(state, _: PayloadAction) {
            state.currentBestellungLoadingState = RequestState.FAILED;
        },
        getBestellungen(state, _: PayloadAction<GetBestellungenAction>) {
            state.bestellungenLoadingState = RequestState.LOADING;
        },
        getBestellungenSucceeded(state, action: PayloadAction<GetBestellungenSucceededAction>) {
            state.bestellungen = action.payload.bestellungen;
            state.totalCount = action.payload.totalCount;
            state.bestellungenLoadingState = RequestState.SUCCESSFUL;
        },
        getBestellungenFailed(state, _: PayloadAction) {
            state.bestellungenLoadingState = RequestState.FAILED;
        },
        addToSammelbestellung(state, action: PayloadAction<string>) {
            const bestellnummerToAdd = action.payload;
            if (!state.currentSammelbestellung.includes(bestellnummerToAdd)) {
                state.currentSammelbestellung.push(bestellnummerToAdd);
            }
        },
        removeFromSammelbestellung(state, action: PayloadAction<string>) {
            const bestellnummerToDelete = action.payload;
            state.currentSammelbestellung = state.currentSammelbestellung.filter(
                (bestellnummer) => bestellnummer !== bestellnummerToDelete
            );
        },
        resetCurrentSammelbestellung(state, _: PayloadAction) {
            state.currentSammelbestellung = [];
        },
    },
});

export const {
    getBestellung,
    getBestellungFailed,
    getBestellungSucceeded,
    getBestellungen,
    getBestellungenFailed,
    getBestellungenSucceeded,
    addToSammelbestellung,
    removeFromSammelbestellung,
    resetCurrentSammelbestellung,
    resetCurrentBestellung,
} = bestelluebersichtSlice.actions;
export const bestelluebersichtReducer = bestelluebersichtSlice.reducer;
