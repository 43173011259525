// Generated code; DO NOT EDIT

const buildinfo = {
    version: '0.1.0',
    gitcommit: '08acbc7',
    buildDate: '2024-06-06T18:29:28.979Z',
};

console.log('version: 0.1.0 git: 08acbc7 date: 2024-06-06T18:29:28.979Z');

export default buildinfo;
