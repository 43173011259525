import { ArrayHelpers, FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { ArtikelNutztierfutterFormular, mapArtikelToOptions } from '../../helper/artikel-helper';
import { useDispatch, useSelector } from 'react-redux';
import { mergeAndSortOptions, Option } from '../../helper/select-options';
import { getWerkeForArtikel } from '../../../store/Abholwerk.store';
import { v4 as uuidv4 } from 'uuid';
import styles from './Artikelauswahl.module.scss';
import textItemStyles from '../../ui-components/TextItem/TextItem.module.scss';
import SelectInput from '../../ui-components/SelectInput/SelectInput';
import Button from '../../ui-components/Button/Button';
import { ReactElement, useEffect, useState } from 'react';
import { MenuList } from './MenuList/MenuList';
import { ArtikelSackwarenSelectors } from '../../../store/ArtikelSackware.store';
import { selectArtikelLoseWareIsLoading, selectArtikelLoseWareList } from '../../../store/ArtikelLoseWare.store';
import { Berechnungsart } from '../../types/enums';
import { emptyArtikelLoseWare } from '../../types/defaultValues';
import type { ArtikelLoseWare, ArtikelSackware, BestellpositionArtikelLoseWareView } from '../../types';
import { NEWID_PREFIX } from '../../constants';
import { useRechnungsempfaengerFromBestellung } from '../../hooks/useRechnungsempfaengerFromBestellung';
import { selectListOfSelectedArtikel, setArtikelNummern } from '../../../store/Artikelauswahl.store';

interface ArtikelauswahlNutztierfutterProps {
    artikelSackwareList?: ArtikelSackware[];
    artikelLoseWareList?: ArtikelLoseWare[];
    allowAddingNewArticles?: boolean;
}

export const Artikelauswahl = ({
    artikelSackwareList,
    artikelLoseWareList,
    allowAddingNewArticles = true,
}: ArtikelauswahlNutztierfutterProps): ReactElement => {
    const selectedArtikelNummern = useSelector(selectListOfSelectedArtikel);
    const loseWareIsLoading = useSelector(selectArtikelLoseWareIsLoading);
    const sackwareIsLoading = useSelector(ArtikelSackwarenSelectors.loading);
    const artikelLoading = sackwareIsLoading || loseWareIsLoading;
    const [selectOptions, setSelectOptions] = useState<Option[]>([]);

    useEffect(() => {
        const artikelSackwareOptions = artikelSackwareList?.map(mapArtikelToOptions) ?? [];
        const artikelLoseWareOptions = artikelLoseWareList?.map(mapArtikelToOptions) ?? [];
        const sortedArtikelOptions = mergeAndSortOptions(artikelSackwareOptions, artikelLoseWareOptions);
        const availableOptions = sortedArtikelOptions.filter(
            (options) => !selectedArtikelNummern.some((artikelNummer) => options.value === artikelNummer)
        );
        setSelectOptions(availableOptions);
    }, [artikelLoseWareList, artikelSackwareList, selectedArtikelNummern]);

    return (
        <FieldArray
            name="bestellpositionenView"
            render={(arrayHelpers): ReactElement => (
                <>
                    <div className={styles._searchContainer}>
                        <div className={styles._selectContainer}>
                            {artikelLoading ? (
                                <div className={textItemStyles._text}>Artikel werden geladen</div>
                            ) : (
                                <SelectInput
                                    classNamePrefix={'react_select__form-artikel'}
                                    id="addArticle"
                                    name="addArticle"
                                    options={selectOptions}
                                    components={{ MenuList }}
                                    placeholder="Artikel hier eingeben (Suchfunktion)"
                                    hideNativeSelect={true}
                                    isClearable={false}
                                    autoSelectIfOnlyOneOption
                                />
                            )}
                        </div>
                        <AddArtikelButton arrayHelpers={arrayHelpers} />
                    </div>
                    {allowAddingNewArticles ? <AddNewArtikel arrayHelpers={arrayHelpers} /> : null}
                </>
            )}
        />
    );
};

type AddArtikelButtonProps = {
    arrayHelpers: FieldArrayRenderProps;
};

const AddArtikelButton = ({ arrayHelpers }: AddArtikelButtonProps): ReactElement => {
    const formikProps = useFormikContext<ArtikelNutztierfutterFormular>();
    const dispatch = useDispatch();
    const artikelSackwareList = useSelector(ArtikelSackwarenSelectors.list);
    const rechnungsempfaengerFromBestellung = useRechnungsempfaengerFromBestellung();
    const artikelLoseWareList = useSelector(selectArtikelLoseWareList);
    const selectedArtikelNummern = useSelector(selectListOfSelectedArtikel);

    function onAddArtikel(): void {
        const artikelToAdd = formikProps.values.addArticle;
        const alreadyInBestellpositionen = selectedArtikelNummern.find((artikel) => artikel === artikelToAdd);

        if (alreadyInBestellpositionen) {
            return;
        }

        const artikelSackware = artikelSackwareList.find((a) => a.nummer === artikelToAdd);
        if (artikelSackware) {
            arrayHelpers.push({
                ...artikelSackware,
                mengeVe: '',
                mengePal: '',
                werkId: '',
            });
        }
        const artikelLoseWare = artikelLoseWareList.find((a) => a.nummer === artikelToAdd);
        if (artikelLoseWare) {
            const neueBestellposition: BestellpositionArtikelLoseWareView = {
                ...artikelLoseWare,
                menge: '',
                werkId: '',
                zulage: false,
                berechnungsart: Berechnungsart.KeinVorgabe,
            };
            arrayHelpers.push(neueBestellposition);
        }
        if (artikelToAdd) {
            dispatch(setArtikelNummern([...selectedArtikelNummern, artikelToAdd]));
        }
        if (artikelToAdd && rechnungsempfaengerFromBestellung?.partnerNummer) {
            dispatch(getWerkeForArtikel(artikelToAdd, rechnungsempfaengerFromBestellung.partnerNummer));
        }
    }

    return (
        <div className={styles._artikelButtonContainer}>
            <Button data-cy="workflow-artikelauswaehlen" type="button" onClick={onAddArtikel}>
                <>
                    <span className={styles._hinzufuegenText}>Artikel auswählen</span>
                    <span className={styles._hinzufuegenPlus}>+</span>
                </>
            </Button>
        </div>
    );
};

type AddNewArtikelProps = {
    arrayHelpers: FieldArrayRenderProps;
};

const AddNewArtikel = ({ arrayHelpers }: AddNewArtikelProps): ReactElement => {
    const dispatch = useDispatch();
    const rechnungsempfaengerFromBestellung = useRechnungsempfaengerFromBestellung();

    function onAddNewArtikel(arrayHelpers: ArrayHelpers): void {
        const artikelNummer = NEWID_PREFIX + uuidv4();
        if (artikelNummer && rechnungsempfaengerFromBestellung?.partnerNummer) {
            dispatch(getWerkeForArtikel(artikelNummer, rechnungsempfaengerFromBestellung?.partnerNummer));
            arrayHelpers.push({
                ...emptyArtikelLoseWare,
                nummer: artikelNummer,
                mengeVe: '',
                mengePal: '',
                werkId: '',
                berechnungsart: Berechnungsart.KeinVorgabe,
            });
        }
    }

    return (
        <div className={styles._neuerArtikel}>
            <span className={styles._neuerArtikelText}>Sie finden einen Artikel nicht?</span>
            <div className={styles._artikelButtonContainer}>
                <Button data-cy="lw-erfassen" isSecondary={true} type="button" onClick={(): void => onAddNewArtikel(arrayHelpers)}>
                    <>
                        <span className={styles._hinzufuegenText}>Fehlenden Artikel erfassen</span>
                        <span className={styles._hinzufuegenPlus}>+</span>
                    </>
                </Button>
            </div>
        </div>
    );
};
